import React from 'react';
import {
  Table,
  Button,
} from "reactstrap";
import Media from 'react-media';

class CustomCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.loading,
    }
  }

  render() {
    return (
      <div className="custom-card-result">
        <p className="paragrafo-descricao paragrafo-teste">
          <strong>{this.props.estilo}</strong> <br />
          <br />
          {this.props.estiloTexto}
        </p>
        <Table responsive>
          <thead>
            <tr>
              <th>Totais</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Desempenho Produtivo
                </td>
              <td>
              </td>
              <td>
                <button
                  title={this.props.handleTdTitle(this.props.estiloProdutivo.label)}
                  type="button"
                  className="btn btn-success margim-button-result"
                  onClick={this.props.handleEstiloModalProdutivo}
                >
                  {`${this.props.estiloProdutivo.label} - ${this.props.estiloProdutivo.value}`}
                </button>
              </td>
            </tr>
            <tr>
              <td>
                Queda de desempenho
                  </td>
              <td>
              </td>
              <td>
                <button
                  title={this.props.handleTdTitle(this.props.estiloQueda.label)}
                  type="button"
                  className="btn btn-success margim-button-result"
                  onClick={this.props.handleEstiloModalQueda}
                >
                  {`${this.props.estiloQueda.label} - ${this.props.estiloQueda.value}`}
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
        <ul>
          <li>
            {`Clique em ${this.props.estiloProdutivo.label} para acessar a descrição do seu estilo predominante em desempenho produtivo.`}
          </li>
          <li className="custom-margim-top2">
            {`Clique em ${this.props.estiloQueda.label} para acessar a descrição do seu estilo predominante em queda de desempenho.`}
          </li>
        </ul>
        <Media query="(min-width: 599px)" render={() => (
          <>
            <Button className="margim-button-pdf"
              color="success"
              type="button"
              onClick={this.props.handleProdutivoPDFModal}>
              PRODUTIVO PDF <i className="fas fa-file-pdf"></i>
            </Button>
            <Button
              className="custom-margim-left-button-pdf custom2-margim-left-button-pdf"
              color="danger"
              type="button"
              onClick={this.props.handleQuedaPDFModal}>
              QUEDA PDF <i className="fas fa-file-pdf"></i>
            </Button>
          </>
        )} />
      </div>
    )
  }
}

export default CustomCollapse;