import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// import AdminLayout from "layouts/Admin.jsx";
import indexRoutes from './routes/index';

const hist = createBrowserHistory();

ReactDOM.render(
  <>
    <div className="top-element">
      <NotificationContainer />
    </div>
    {/* <Router history={hist}>
      <Switch>
        <Route render={props => <AdminLayout {...props} />} />
        <Redirect to="/home" />
      </Switch>
    </Router> */}
    <Router history={hist}>
      <Switch>
        {indexRoutes.map((prop, key) => <Route path={prop.path} key={key} component={prop.component} />)}
      </Switch>
    </Router>
  </>,
  document.getElementById("root")
);
