import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import Roboto from '../../assets/fonts/Roboto-Bold.ttf';
import { Spinner } from 'reactstrap';

const styles = StyleSheet.create({
  title: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 17,
    textAlign: 'center',
    color: '#6d6868',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  subitle: {
    marginLeft: 95,
    color: '#6d6868',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  body: {
    flexGrow: 1,
    margin: 10,
  },
  row: {
    flexDirection: 'row',
  },
  paragraph: {
    maxWidth: 640,
    marginLeft: 95,
    color: '#6d6868',
    position: 'relative',
    textAlign: 'justify',
    marginTop: 10,
  },
  bold: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  item: {
    flexDirection: 'row',
    marginBottom: 5,
    marginLeft: 105,
    maxWidth: 630,
    color: '#6d6868',
  },
  bulletPoint: {
    width: 10,
    fontSize: 20,
  },
  itemContent: {
    marginLeft: 5,
  },
  centralizeStyle: {
    textAlign: 'center',
  },
  marginTop1: {
    marginTop: 20,
  },
  marginTop2: {
    marginTop: 13,
  },
});

Font.register({ family: 'Roboto', src: Roboto });

class ResultadoQuedaPDF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movimentacoesData: this.props.data,
      movimentacoesPorDia: this.props.movimentacoesPorDia,
      dataLoaded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        dataLoaded: true,
      })
    }, 500);
  }

  render() {
    return (
      <>
        {this.state.dataLoaded ?
          <PDFViewer style={{ width: '100%', height: '600px' }}>
            <Document>
              <Page style={{ paddingBottom: 25, paddingTop: 25 }} size="A4" orientation="landscape">
                {this.props.estiloQueda.label === 'M/C' &&
                  <>
                    <Text style={styles.title}>O ESTILO MANTÉM E CONSERVA (M/C)</Text>
                    <View style={styles.body}>
                      <View style={styles.row}>
                        <Text style={styles.paragraph}>
                          Você tende a se prender muito a fatos e inundar os outros com dados para vencer uma
                          discussão, depois se retira, matem sua posição e espera para ver uma reação, ou até que a competição
                          chegue a você. Você tende a superanalisar um problema e, portando, a colecionar dados desnecessários. Você
                          se atém, possessivamente, aquilo que tem, protege-se e fica atento a seus próprios interesses, mesmo que
                          possa fazer os outros pensarem que você é teimoso, frio ou indiferente. Você tende a se apegar demais ao
                          velho em vez de aceitar o novo.
                        </Text>
                      </View>
                    </View>
                  </>
                }
                {this.props.estiloQueda.label === 'T/C' &&
                  <>
                    <Text style={styles.title}>O ESTILO TOMA E CONTROLA (T/C)</Text>
                    <View style={styles.body}>
                      <View style={styles.row}>
                        <Text style={styles.paragraph}>
                          Você tende a usar o estilo ”ou vai ou racha”. Você se torna vigoroso demais lutando por
                          seus direitos e usando o rolo compressor, decidido a não deixar ninguém passá-lo para trás. Você pode tentar
                          medir forces intelectuais com seu adversário e entrar numa polêmica radical (ganha-perde), mesmo que seja
                          para vencer a batalha e perder a guerra. Os outros podem achar você super agressivo.
                        </Text>
                      </View>
                    </View>
                  </>
                }
                {this.props.estiloQueda.label === 'A/N' &&
                  <>
                    <Text style={styles.title}>O ESTILO ADAPTA E NEGOCIA (A/N)</Text>
                    <View style={styles.body}>
                      <View style={styles.row}>
                        <Text style={styles.paragraph}>
                          Você tende a transigir demais, adaptando-se e seguindo muito facilmente as opiniões dos
                          outros. Você tenta evitar o conflito, manter a paz e a harmonia a todo custo. Você, frequentemente, tende a
                          apaziguar os criadores de casos procurando gracejar sobre o problema, levando na brincadeira, e negando a
                          preocupação real. Você tende a suavizar as coisas com seu charme, a esconder seus sentimentos reais e a
                          conciliar para manter todos contentes, mesmo que você parece ansioso demais para obter aprovação dos outros.
                        </Text>
                      </View>
                    </View>
                  </>
                }
                {this.props.estiloQueda.label === 'D/A' &&
                  <>
                    <Text style={styles.title}>O ESTILO DÁ E APÓIA (D/A)</Text>
                    <View style={styles.body}>
                      <View style={styles.row}>
                        <Text style={styles.paragraph}>
                          Você tende a ser excessivamente útil, acreditando nos outros e confiando e concedendo demais.
                          Você aceita o ponto de vista dos outros muito facilmente afim de ser considerado um bom sujeito. Você tende a
                          perguntar demais a opinião dos outros, não colocando as suas com firmeza, e perguntando o que fazer. Você
                          tende a assumir rapidamente a culpa pelo conflito, acreditando que você seja a causa dele e procurando uma
                          maneira de conciliar. Você pode parecer um fraco aos olhos dos outros.
                        </Text>
                      </View>
                    </View>
                  </>
                }
              </Page>
            </Document>
          </PDFViewer>
          : <div style={{ textAlign: 'center' }}>
            <Spinner style={{
              width: '7rem',
              height: '7rem',
              color: '#2B6936',
            }}/>
          </div>
        }
      </>
    )
  }
}

export default ResultadoQuedaPDF;