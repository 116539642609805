import React from "react";
import Media from 'react-media';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ExemploModal from './ExemploModal';
import { Link } from 'react-router-dom';
import Estilos from '../../components/Estilos/Estilos';

class PaginaInicial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exemploModal: false,
    };
  }

  abrirExemploModal = () => {
    this.setState({
      exemploModal: !this.state.exemploModal,
    });
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="11">
              <Card className="card-upgrade">
                <CardHeader className="text-center">
                  <CardTitle>
                    <h4>
                      Seja bem-vindo ao LIFO <i className="far fa-registered registered-icon-inicial" />
                    </h4>
                    <h6>LIFO <i className="far fa-registered registered-icon-inicial" /> - LIFE ORIENTATION</h6>
                  </CardTitle>
                  <p className="paragrafo-inicial">
                    Os autores <strong>Dr. Stuart Atkins</strong> e <strong>Dr. Allan Katcher</strong> desenvolveram este método de pesquisa
                    com a finalidade de ajudar as pessoas a entender suas preferências comportamentais, como melhorar a si mesmas e melhorar
                    os relacionamentos, estudando a interação dos estilos e pontos fortes de cada um. <br />
                  </p>
                </CardHeader>
                <CardBody>
                  <h4 className="text-center">Interpretação</h4>
                  <Card className="card-exemplos">
                    <br />
                    <div className="paragrafo-descricao  paragrafo-teste">
                      Os autores do LIFO colocam como aspecto básico o fato de que as pessoas não são portadoras de um
                    único estilo, mas apresentam os quatro estilos ao mesmo tempo. <br />
                    O que as diferencia entre si é a <strong>ordem nas
                    quais essas quatro orientações comportamentais</strong> aparecem. Sendo determinada pela
                    intensidade com a qual cada <br /> tipo de estilo é usado. <br />
                      <br />
                    Cada pessoa pode ter um determinado conjunto de estilos característicos de desempenho sob condições habituais ou costumeiras, podendo apresentar já um outro arranjo
                    diferente quando em situações depressão. <br />
                      <br />
                    Uma vez conhecendo seus pontos fortes e possíveis excessos, o indivíduo passa a dispor de um conjunto de recursos que visam facilitar o planejamento de uma
                    estratégia comportamental para que possa utilizar-se ao máximo de seus pontos fortes.
                    <br />
                      <br /> Evitando situações em que seu estilo menos desenvolvido seja solicitado,
                    apresentando-se, assim, mais produtivo para a
                    organização e, principalmente, chegando a experimentar maior grau de conforto pessoal em situação de trabalho.
                      <br />
                      <br />
                    A soma de pontos obtidas através do questionário de diagnóstico é indicativa da intensidade
                    em que cada uma dessas orientações foi aceita pelo indivíduo, como descritiva da sua própria maneira de ser.
                    </div>
                  </Card>
                  <h4 className="text-center">Orientações para o questionário</h4>
                  <br />
                  <p className="paragrafo-descricao">
                    Este não é um teste de respostas certas ou erradas, mas um questionário que lhe permite descrever suas orientações predominantes em relação à vida,
                    a fim de identificar se você usa suas forças de um modo <strong>produtivo</strong> ou <strong>improdutivo</strong>.
                  </p>
                  <br />
                  <p className="paragrafo-descricao">
                    Clique em exemplo para preencher o formulário de demonstração.
                  <br />
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={this.abrirExemploModal}
                    >
                      Exemplo
                  </button>
                  </p>
                  <Card className="card-exemplos">
                    <br />
                    <p className="paragrafo-descricao paragrafo-teste">
                      Verifique, como no exemplo acima, que cada alternativa <strong>deverá ser classificada com um número diferente</strong>.
                      Se as afirmações deste questionário tiverem duas ou mais alternativas que parecem ter o mesmo peso
                      para você, ou se qualquer delas não se aplica absolutamente
                      a você, mesmo assim classifique-as, embora seja difícil. <br />
                      <strong>
                        Selecione para cada afirmativa um número entre 4, 3, 2 ou 1, de acordo com a alternativa que
                        mais se parece com você (4), até a alternativa que menos se parece com você (1).
                      </strong>
                    </p>
                  </Card>
                  <Media query="(max-width: 500px)" render={() => (
                    <>
                      <p className="paragrafo-descricao">
                        Clique em Iniciar começar o seu teste.
                        <br />
                        <Link
                        className="btn btn-success"
                        to="/questionario"
                        >
                          Iniciar
                        </Link>
                      </p>
                    </>
                  )} />
                  <Media query="(min-width: 599px)" render={() => (
                    <>
                      <p className="paragrafo-descricao">
                        <span className="iniciar-form">Clique em Questionário na barra lateral para iniciar o LIFO.</span>
                      </p>
                    </>
                  )} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal className="new-expense-modal" isOpen={this.state.exemploModal} toggle={this.abrirExemploModal}>
          <ModalHeader toggle={this.abrirExemploModal}>Exemplo</ModalHeader>
          <ModalBody>
            <ExemploModal />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.abrirExemploModal}>Fechar</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default PaginaInicial;
