import React from "react";
import Cookies from "js-cookie";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import Api from "../../config/Api";
import EstiloModalProdutivo from "./EstiloModalProdutivo";
import EstiloModalQueda from "./EstiloModalQueda";
import Totais from "../../components/Totais/Totais";
import Estilos from "../../components/Estilos/Estilos";
import ResultadoProdutivoPDF from "./ResultadoProdutivoPDF";
import ResultadoQuedaPDF from "./ResultadoQuedaPDF";

export default class Resultado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get("TOKEN"),
      usuario: Cookies.get("USERNAME"),
      formId: 1,
      quiz: [],
      loading: true,
      estiloModalProdutivo: false,
      estiloModalQueda: false,
      produtivoPDFModal: false,
      quedaPDFModal: false,
      estilosProdutivo: [
        { label: "D/A", value: 0 },
        { label: "T/C", value: 0 },
        { label: "M/C", value: 0 },
        { label: "A/N", value: 0 },
      ],
      estilosQueda: [
        { label: "D/A", value: 0 },
        { label: "T/C", value: 0 },
        { label: "M/C", value: 0 },
        { label: "A/N", value: 0 },
      ],
      primeiroEstiloQueda: 0,
      primeiroEstiloProdutivo: 0,
    };

    this.api = new Api(this.state.token, (err) => {
      if (err.response.status === 401)
        this.props.history.push({
          pathname: "/login",
          state: { previousURL: this.props.location.pathname },
        });
      return Promise.reject(err);
    });
  }

  async componentDidMount() {
    await this.getRespostas();
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 400);
  }

  getRespostas = async () => {
    try {
      const { data } = await this.api.userAnswers(this.state.usuario);
      if (data.lifoData && data.lifoData.length) {
        const lifoData = data.lifoData;
        this.setState({
          respondido: true,
          lifoData,
        });
        this.handleLifoTotais();
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleLifoTotais = () => {
    let quedaArray = [];
    let produtivoArray = [];

    // transformar em um array chave valor
    const values = Object.entries(this.state.lifoData[0]);
    values.forEach((value) => {
      if (value[0].includes("queda")) {
        quedaArray.push(value);
      } else {
        produtivoArray.push(value);
      }
    });

    produtivoArray = this.preencherValores(
      this.state.estilosProdutivo,
      produtivoArray
    );
    quedaArray = this.preencherValores(this.state.estilosQueda, quedaArray);

    const estilosProdutivo = produtivoArray.sort((a, b) => a.value - b.value);
    const estilosQueda = quedaArray.sort((a, b) => a.value - b.value);

    const primeiroEstiloProdutivo = estilosProdutivo[3];
    const primeiroEstiloQueda = estilosQueda[3];
    const segundoEstiloProdutivo = estilosProdutivo[2];
    const segundoEstiloQueda = estilosQueda[2];
    const terceiroEstiloProdutivo = estilosProdutivo[1];
    const terceiroEstiloQueda = estilosQueda[1];
    const quartoEstiloProdutivo = estilosProdutivo[0];
    const quartoEstiloQueda = estilosQueda[0];

    this.setState({
      primeiroEstiloProdutivo,
      primeiroEstiloQueda,
      segundoEstiloProdutivo,
      segundoEstiloQueda,
      terceiroEstiloProdutivo,
      terceiroEstiloQueda,
      quartoEstiloProdutivo,
      quartoEstiloQueda,
    });
  };

  preencherValores = (listToInclude, listBase) => {
    const estilos = listToInclude;
    listBase.forEach((item) => {
      if (item[0].includes("d_a")) {
        estilos[0].value = item[1];
        return;
      } else if (item[0].includes("t_c")) {
        estilos[1].value = item[1];
        return;
      } else if (item[0].includes("m_c")) {
        estilos[2].value = item[1];
        return;
      } else {
        estilos[3].value = item[1];
        return;
      }
    });
    return estilos;
  };

  handleEstiloModalProdutivo = (estilo) => () => {
    this.setState({
      estiloModalProdutivo: !this.state.estiloModalProdutivo,
      atualEstiloProdutivo: estilo,
    });
  };

  handleEstiloModalQueda = (estilo) => () => {
    this.setState({
      estiloModalQueda: !this.state.estiloModalQueda,
      atualEstiloQueda: estilo,
    });
  };

  handleProdutivoPDFModal = (estilo) => () => {
    this.setState({
      produtivoPDFModal: !this.state.produtivoPDFModal,
      atualEstiloProdutivo: estilo,
    });
  };

  handleQuedaPDFModal = (estilo) => () => {
    this.setState({
      quedaPDFModal: !this.state.quedaPDFModal,
      atualEstiloQueda: estilo,
    });
  };

  handleCloseProdutivoPdfModal = () => {
    this.setState({
      produtivoPDFModal: !this.state.produtivoPDFModal,
    });
  };

  handleCloseQuedaPdfModal = () => {
    this.setState({
      quedaPDFModal: !this.state.quedaPDFModal,
    });
  };

  handleTdTitle = (estilo) => {
    if (estilo === "A/N") {
      return "ADAPTA E NEGOCIA";
    } else if (estilo === "M/C") {
      return "MANTÉM E CONSERVA";
    } else if (estilo === "T/C") {
      return "TOMA E CONTROLA";
    } else {
      return "DÁ E APÓIA";
    }
  };

  render() {
    return (
      <>
        <div className="content">
          {this.state.loading ? (
            <div style={{ textAlign: "center" }}>
              <Spinner
                style={{
                  width: "7rem",
                  height: "7rem",
                  marginTop: "150px",
                  color: "#2B6936",
                }}
              />
            </div>
          ) : this.state.respondido ? (
            <Row>
              <Col className="ml-auto mr-auto" md="11">
                <Card className="card-upgrade">
                  <CardHeader className="text-center">
                    <CardTitle tag="h4">Resultado</CardTitle>
                  </CardHeader>
                  <CardBody className="custom-card-body">
                    <div className="custom-card-result">
                      <p className="paragrafo-descricao paragrafo-teste">
                        A soma de pontos obtidas através do questionário de
                        diagnóstico é a indicativa da intensidade em que cada
                        uma dessas orientações foi aceita pelo indivíduo, como
                        descritiva da sua própria maneira de ser. <br />
                      </p>
                      <br />
                      <Table style={{ textAlign: "center" }}>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Desempenho Produtivo</th>
                            <th>Queda de desempenho</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1º Estilo</td>
                            <td
                              title={this.handleTdTitle(
                                this.state.primeiroEstiloProdutivo.label
                              )}
                            >
                              {`${this.state.primeiroEstiloProdutivo.label} - ${this.state.primeiroEstiloProdutivo.value}`}
                            </td>
                            <td
                              title={this.handleTdTitle(
                                this.state.primeiroEstiloQueda.label
                              )}
                            >
                              {`${this.state.primeiroEstiloQueda.label} - ${this.state.primeiroEstiloQueda.value}`}
                            </td>
                          </tr>
                          <tr>
                            <td>2º Estilo</td>
                            <td
                              title={this.handleTdTitle(
                                this.state.segundoEstiloProdutivo.label
                              )}
                            >
                              {`${this.state.segundoEstiloProdutivo.label} - ${this.state.segundoEstiloProdutivo.value}`}
                            </td>
                            <td
                              title={this.handleTdTitle(
                                this.state.segundoEstiloQueda.label
                              )}
                            >
                              {`${this.state.segundoEstiloQueda.label} - ${this.state.segundoEstiloQueda.value}`}
                            </td>
                          </tr>
                          <tr>
                            <td>3º Estilo</td>
                            <td
                              title={this.handleTdTitle(
                                this.state.terceiroEstiloProdutivo.label
                              )}
                            >
                              {`${this.state.terceiroEstiloProdutivo.label} - ${this.state.terceiroEstiloProdutivo.value}`}
                            </td>
                            <td
                              title={this.handleTdTitle(
                                this.state.terceiroEstiloQueda.label
                              )}
                            >
                              {`${this.state.terceiroEstiloQueda.label} - ${this.state.terceiroEstiloQueda.value}`}
                            </td>
                          </tr>
                          <tr>
                            <td>4º Estilo</td>
                            <td
                              title={this.handleTdTitle(
                                this.state.quartoEstiloProdutivo.label
                              )}
                            >
                              {`${this.state.quartoEstiloProdutivo.label} - ${this.state.quartoEstiloProdutivo.value}`}
                            </td>
                            <td
                              title={this.handleTdTitle(
                                this.state.quartoEstiloQueda.label
                              )}
                            >
                              {`${this.state.quartoEstiloQueda.label} - ${this.state.quartoEstiloQueda.value}`}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Estilos
                        children1={
                          <Totais
                            estilo="1º Estilo"
                            estiloTexto="
                              É aquele que apresenta maior soma de pontos. Também denominado estilo de frente, caracterizando um comportamento que a
                              pessoa exibe com grande facilidade. É, em suma, uma forma de atuação quase automática, configurando a maneira preferida
                              de cada um ser."
                            handleEstiloModalProdutivo={this.handleEstiloModalProdutivo(
                              this.state.primeiroEstiloProdutivo
                            )}
                            handleEstiloModalQueda={this.handleEstiloModalQueda(
                              this.state.primeiroEstiloQueda
                            )}
                            estiloProdutivo={this.state.primeiroEstiloProdutivo}
                            estiloQueda={this.state.primeiroEstiloQueda}
                            handleProdutivoPDFModal={this.handleProdutivoPDFModal(
                              this.state.primeiroEstiloProdutivo
                            )}
                            handleQuedaPDFModal={this.handleQuedaPDFModal(
                              this.state.primeiroEstiloQueda
                            )}
                            handleTdTitle={this.handleTdTitle}
                          />
                        }
                        children2={
                          <Totais
                            estilo="2º Estilo"
                            estiloTexto="
                            Também conhecido como estilo de fundo, sendo de certa forma menos frequente que o anterior, caracteriza-se esse segundo
                            estilo como um comportamento que o indivíduo usa quando quer.
                            É esse segundo estilo que dá colorido especial ao primeiro, atenuando seu aparecimento de forma pura."
                            handleEstiloModalProdutivo={this.handleEstiloModalProdutivo(
                              this.state.segundoEstiloProdutivo
                            )}
                            handleEstiloModalQueda={this.handleEstiloModalQueda(
                              this.state.segundoEstiloQueda
                            )}
                            estiloProdutivo={this.state.segundoEstiloProdutivo}
                            estiloQueda={this.state.segundoEstiloQueda}
                            handleProdutivoPDFModal={this.handleProdutivoPDFModal(
                              this.state.segundoEstiloProdutivo
                            )}
                            handleQuedaPDFModal={this.handleQuedaPDFModal(
                              this.state.segundoEstiloQueda
                            )}
                            handleTdTitle={this.handleTdTitle}
                          />
                        }
                        children3={
                          <Totais
                            estilo="3º Estilo"
                            estiloTexto="
                              Trata-se de um comportamento bem menos frequente que os dois primeiros, sendo aquele que o indivíduo só utiliza quando seja absolutamente necessário.
                              É por essa razão que esse terceiro estilo requer maior esforço pessoal, quando se torna indicada sua utilização."
                            handleEstiloModalProdutivo={this.handleEstiloModalProdutivo(
                              this.state.terceiroEstiloProdutivo
                            )}
                            handleEstiloModalQueda={this.handleEstiloModalQueda(
                              this.state.terceiroEstiloQueda
                            )}
                            estiloProdutivo={this.state.terceiroEstiloProdutivo}
                            estiloQueda={this.state.terceiroEstiloQueda}
                            handleProdutivoPDFModal={this.handleProdutivoPDFModal(
                              this.state.terceiroEstiloProdutivo
                            )}
                            handleQuedaPDFModal={this.handleQuedaPDFModal(
                              this.state.terceiroEstiloQueda
                            )}
                            handleTdTitle={this.handleTdTitle}
                          />
                        }
                        children4={
                          <Totais
                            estilo="4º Estilo"
                            estiloTexto="
                              Também chamado de força negligenciada. Retrata um comportamento não frequente e extremamente desgastante, quando da necessidade de sua utilização.
                              A produtividade conseguida com a utilização do quarto estilo é sempre insatisfatória em termos organizacionais."
                            handleEstiloModalProdutivo={this.handleEstiloModalProdutivo(
                              this.state.quartoEstiloProdutivo
                            )}
                            handleEstiloModalQueda={this.handleEstiloModalQueda(
                              this.state.quartoEstiloQueda
                            )}
                            estiloProdutivo={this.state.quartoEstiloProdutivo}
                            estiloQueda={this.state.quartoEstiloQueda}
                            handleProdutivoPDFModal={this.handleProdutivoPDFModal(
                              this.state.quartoEstiloProdutivo
                            )}
                            handleQuedaPDFModal={this.handleQuedaPDFModal(
                              this.state.quartoEstiloQueda
                            )}
                            handleTdTitle={this.handleTdTitle}
                          />
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="custom-card-result">
              <span className="span-result-warning">
                Você deve responder o questionário para ter acesso ao resultado!
              </span>
            </div>
          )}
        </div>
        {/* Desempenho Produtivo */}
        <Modal
          className="new-expense-modal"
          isOpen={this.state.estiloModalProdutivo}
          toggle={this.handleEstiloModalProdutivo("")}
        >
          <ModalHeader toggle={this.handleEstiloModalProdutivo("")}>
            Desempenho Produtivo
          </ModalHeader>
          <ModalBody>
            <EstiloModalProdutivo
              estiloProdutivo={this.state.atualEstiloProdutivo}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.handleEstiloModalProdutivo("")}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        {/* Queda de desempenho */}
        <Modal
          className="new-expense-modal"
          isOpen={this.state.estiloModalQueda}
          toggle={this.handleEstiloModalQueda("")}
        >
          <ModalHeader toggle={this.handleEstiloModalQueda("")}>
            Queda de desempenho
          </ModalHeader>
          <ModalBody>
            <EstiloModalQueda estiloQueda={this.state.atualEstiloQueda} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleEstiloModalQueda("")}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        {/* PDF - Desempenho Produtivo */}
        <Modal
          className="new-expense-modal"
          isOpen={this.state.produtivoPDFModal}
          toggle={this.handleCloseProdutivoPdfModal}
        >
          <ModalHeader toggle={this.handleCloseProdutivoPdfModal}>
            Desempenho Produtivo
          </ModalHeader>
          <ModalBody>
            <ResultadoProdutivoPDF
              estiloProdutivo={this.state.atualEstiloProdutivo}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.handleCloseProdutivoPdfModal}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        {/* PDF - Queda de desempenho */}
        <Modal
          className="new-expense-modal"
          isOpen={this.state.quedaPDFModal}
          toggle={this.handleCloseQuedaPdfModal}
        >
          <ModalHeader toggle={this.handleCloseQuedaPdfModal}>
            Queda de desempenho
          </ModalHeader>
          <ModalBody>
            <ResultadoQuedaPDF estiloQueda={this.state.atualEstiloQueda} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleCloseQuedaPdfModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
