import axios from 'axios';

export default class Api {
  constructor(token, onError) {
    this.axios = axios.create({
      baseURL: 'https://api-lifo.evoluire.com.br',
      // baseURL: 'http://localhost:3333',
      headers: {
        Authorization: token,
      },
    });

    this.axios.interceptors.response.use((response) => response, onError);
  }

  // Login

  login(username, password) {
    return this.axios.post('/login', { username, password });
  }

  validateToken(token) {
    return this.axios.post('/usuario/check-token', { token });
  }

  //  Form 

  getQuiz(id) {
    return this.axios.get(`/questionario/mount/${id}`);
  }

  answerQuiz(payload) {
    return this.axios.post('/questionario/answer', payload);
  }

  userAnswers(usuario) {
    return this.axios.post('/questionario/user-answers', { usuario });
  }

  checkRespondido(usuario) {
    return this.axios.post('/usuario/check-respondido', { usuario });
  }

  getUserLifoDados(username) {
    return this.axios.post('/questionario/user-data-info', { username });
  }
}