import Notification from '../../components/Notification/Notification';

export default class CheckDuplicity {
  abrirNotificacaoErro = (message) => Notification
    .createNotification('error', message)

  handleDuplicatedOption = (arrayToCheck) => {
    let arrayVerificacao = [];
    let valido;
    arrayToCheck.forEach((alternativaBase, idx) => {
      arrayToCheck.forEach((subAlternativa, sidx) => {
        if (idx === sidx) return;
        if (alternativaBase.value === subAlternativa.value) {
          arrayVerificacao.push(false);
        }
      });
    });

    if (arrayVerificacao.some((item) => !item)) {
      valido = false;
    } else {
      valido = true;
    }
    return valido;
  }

  handleToUser(arrayToCheck) {
    if (!this.handleDuplicatedOption(arrayToCheck)) {
      this.abrirNotificacaoErro('Cada alternativa deve ser classificada com um número diferente!');
      return false;
    } else {
      return true;
    }
  }
}

