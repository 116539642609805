import React from 'react';

export default class EstiloModalProdutivo extends React.Component {
  render() {
    return (
      <div className="content">
        {this.props.estiloProdutivo.label === 'M/C' &&
          <>
            <h5 className="text-center">O ESTILO MANTÉM E CONSERVA (M/C)</h5>
            <p className="paragrafo-inicial">
              É esperado das pessoas que se caracterizam por este estilo que avaliem criticamente os planos, que solicitem documentação,
              que sondem a lógica e as informações que estejam por traz de novas propostas. A atenção ao processo de controle é evidente.
              Estas pessoas pressionam no sentido da estrutura, organização e planejamento detalhado. A precisão é observada e questionada.
              Elas são sensíveis à necessidade de clarificar políticas e procedimentos. Forçam a ao olhar para a utilidade dos resultados das decisões,
              - especialmente em relação às suas consequências financeiras. Existe um esforço para considerar as alternativas em lugar de aceitar a primeira
              resposta que venha à cabeça. Este estilo é especialmente útil em organizações maduras ou durante fases de transição da expansão para a estabilidade.
              Em condições de uso produtivo das suas forças dentro do grupo, este é o indivíduo que se apóia intensamente nos fatos e na análise lógica dos problemas
              e prossegue rumo à solução de forma bastante metódica. Está inclinado a procurar usar ao máximo de pormenores do problema, procurando ressaltar todos os
              aspectos de uma situação antes de abandoná-la.
              Ao utilizar estas suas forças de maneira excessivas, têm propensão a ser relutante em abandonar afirmações ou deixar de lado uma posição inicial.
              Com isto, ele pode pular fora de uma situação de conflito e esperar até que o seu oponente veja com maior clareza. Tem a tendência a relutar em agir até que
            todos os fatos e dados tenham sido levados em conta. A pessoa <strong>Mantém e Conserva</strong> permanece firme quanto aos seus pontos de vista e opiniões parecendo menos aberta
            aos dos outros.
            Ao dirigir as atividades do grupo, é quem planeja os projetos, ao mesmo tempo em que acompanha as atividades e produção deles. Portanto, é evidente que
            maximiza o uso de procedimentos e políticas. É o <strong>Mantém e Conserva</strong> que consegue resolver os problemas em situações que requeiram uma orientação pautada pelos
            aspectos de utilidade prática.
            </p>
            <p className="paragrafo-inicial custom-margim-top1">
              <span><strong>Paradoxos da sua Força e Fraqueza</strong></span> <br />
              Ao adotar características de persistência, pode exagerar impedindo que as coisas deslanchem. É o <strong>Mantém e Conserva</strong> que se preocupa com os aspectos econômicos dos projetos,
              chegando ao ponto de mostrar-se abertamente preocupado com os custos. Trata-se de alguém caracteristicamente controlado, que pode chegar a ser considerado como sem sentimentos.
              O <strong>Mantém e Conserva</strong> é essencialmente lógico, mas pode chegar ao excesso de enfatizar demais o aspecto estrutural. De firme e resoluto, pode chegar ao exagero da obstinação.
            </p>
            <div className="paragrafo-inicial custom-margim-top1">
              <span><strong>O estilo Mantém e Conserva sente-se motivado quando:</strong></span> <br />
              <ul>
                <li>
                  As coordenadas do trabalho lhes são apresentadas de forma lógica dentro de um sistema ordenado, de maneira que possam descobrir de onde vêm, como devem ser feitas e para que
                  servem. Estão trabalhando em projetos que conservam alguma semelhança ou relação nos quais já tenham trabalhado, podendo contar com o tempo necessário para garantir a boa
                  qualidade daquilo que fazem, principalmente tendo como base a consulta a fontes tais como relatórios, estatísticas, levantamentos e pesquisas de dados concretos, balanços,
                  quadros e outros.
                </li>
                <li className="custom-margim-top2">
                  O ambiente é mais estável sob o ponto de vista de normas e políticas formalmente estabelecidas; quando as pessoas que com elas convivem tratam-nas com a maior seriedade
                  cercando-as com uma amizade segura mas não esfuziantemente emocional, deixando claras quais são as intenções e promovendo um tratamento justo, coerente e respeitoso.
                </li>
              </ul>
              A frase: <strong>Preciso que este trabalho seja cuidadosamente examinado</strong>, vai de encontro às expectativas do indivíduo cujo estilo principal
              é o <strong>Mantém e Conserva</strong>.
            </div>
            <div className="paragrafo-inicial custom-margim-top1">
              <span><strong>O estilo Mantém e Conserva se sente desmotivado quando:</strong></span> <br />
              <ul>
                <li>
                  O trabalho lhes é apresentado de forma confusa, por meio de dados incompletos, sem o necessário desencadeamento lógico entre os mesmos. Neste contexto, elas sentem-se perdidas,
                  sem conseguir referencial a ser considerado para atingir aqueles padrões qualitativos de trabalho que lhes fazem sentir mais seguras em termos de sua eficiência pessoal.
                </li>
                <li className="custom-margim-top2">
                  Sentem-se inseridas num contexto no qual normas e políticas organizacionais encontram-se em constante mudança, isto é, cada dia segue-se uma nova orientação. É extremamente frustrante
                  ao <strong>Mantém e Conserva</strong> receber respostas do tipo "eu acho". Não se sentem bem quando devem conviver num ambiente social muito emocional, com explosões
                  efetivas evidentes tendo a sensação de que não são suficientemente levados a sério e que as pessoas se mostram inconsequentes quanto ao julgamento que fazem umas das outras, não levando
                  em conta os resultados de atitudes impensadas. Por este motivo, sentem-se desrespeitados na sua individualidade e injustamente tratados.
                </li>
              </ul>
            </div>
          </>
        }
        {this.props.estiloProdutivo.label === 'T/C' &&
          <>
            <h5 className="text-center">O ESTILO TOMA E CONTROLA (T/C)</h5>
            <p className="paragrafo-inicial">
              Este estilo impulsiona a atividade promovendo um grande envolvimento ao iniciar novos projetos mantendo-os organizados e em funcionamento. Os assuntos serão encarados de frente e
              serão envidados esforços para resolvê-los rapidamente. As pessoas que seguem este estilo encorajam as outras a assumirem riscos e a capitalizarem sobre as oportunidades que aparecem.
              Estão alertas às oportunidades competitivas, podem entusiasmar os outros e até fazê-los cometer excessos. Este estilo será especialmente útil no momento de disparar o início de novas fases
              de novos programas para a expansão da organização.
              O uso produtivo das características comportamentais da pessoa <strong>Toma e Controla</strong>, quando em situação grupal, distingui-la-á ao orientar-se pela ação, sendo, portanto, empreendedora e
              estando desejosa de enfrentar situações que contenham diferentes desafios. Ela gosta de debater pontos de vista, sente-se confortável em situações de mudança, podendo até ser forçada por
              este tipo de necessidade. É a pessoa decidida e que assume riscos com prazer.
              Quando faz uso excessivo de suas características pessoais, pode estar fechada às ideias e opiniões dos outros. Tem tendência a desviar energia do trabalho que está fazendo para controlar
              o que está ocorrendo ou mesmo provar que é capaz de liderar a situação. Muito orientado para a ação, o indivíduo pode ser impulsivo ou reagir emocionalmente a condições de pressão.
              Dirigindo um grupo, ele consegue organizar pessoas e recursos, fazendo com que as coisas funcionem. É no geral quem dá as diretrizes e guia o projeto ou tarefa.
              A pessoa que tem o estilo <strong>Toma e Controla</strong> é quem implementa ideias e programas.
            </p>
            <p className="paragrafo-inicial custom-margim-top1">
              <span><strong>Paradoxos da sua Força e Fraqueza</strong></span> <br />
              Ao dar forte ênfase à orientação para a ação, transforma-se num impulsivo. Ao mesmo tempo, pode ser uma pessoa forte, acentuando esta orientação
              de forma a transformar-se em coercitiva. Sendo capaz de deslanchar sozinha, chega a agir sem ter a devida autorização; o seu desejo de mudança pode
              exacerbar-se a ponto de abandonar antigos procedimentos sem dar a devida consideração sobre se ainda valiam alguma coisa. A urgência que caracteriza seu
              comportamento chega a aumentar sua intensidade a ponto de transformar-se numa pessoa impaciente.
            </p>
            <div className="paragrafo-inicial custom-margim-top1">
              <span><strong>O estilo Toma e Controla sente-se motivado quando:</strong></span> <br />
              <ul>
                <li>
                  São principalmente desafiadas a comprovarem sua eficiência pessoal na resolução de problemas novos e difíceis; portanto, quando são consideradas como competentemente
                  eficazes preferindo dirigir-se autonomamente. Seu maior conforto ocorre quando estão em cargos que exijam iniciativa pessoal e resolução de variados problemas fora da
                  rotina, nos quais investem seus recursos pessoais com o máximo prazer.
                </li>
                <li className="custom-margim-top2">
                  São tratados de igual para igual num ambiente onde os responsáveis não têm medo de assumir o resultado daquilo que fazem. Quando as pessoas dizem diretamente aquilo que
                  pensam sem rodeios para não criar suscetibilidades; onde, portanto, todos são ágeis e rápidos ao se movimentarem e se possa saber de que lado "vem a guerra" para direcionarem
                  suas baterias.
                </li>
              </ul>
              A frase: <strong>Aposto que você não é capaz</strong>, é aquilo que estas pessoas esperam para se porem em ação.
            </div>
            <div className="paragrafo-inicial custom-margim-top1">
              <span><strong>O estilo Toma e Controle se sente desmotivado quando:</strong></span> <br />
              <ul>
                <li>
                  São colocados em cargos rotineiros e repetitivos, não tendo oportunidade de utilizarem-se do seu potencial de aptidões diante de uma situação inesperada e mais complicada. Quando não
                  vêem possibilidade de ascensão e de progresso pessoal, pela falta de estabelecimento de objetivos em sua carreira, e faltam-lhes recursos que lhes permitam vencer as várias etapas
                  para o sucesso final.
                </li>
                <li className="custom-margim-top2">
                  São supervisionadas de perto, não sentindo a autonomia que gostaria de ter para assumirem a total responsabilidade de atuarem como julgarem melhor. Quando sentem que os demais não assumem
                  suas próprias responsabilidades e procuram envolvê-las com pensamentos tortuosos, a fim de se desculparem por suas falhas. Quando se sentem tolhidos.
                </li>
              </ul>
            </div>
          </>
        }
        {this.props.estiloProdutivo.label === 'A/N' &&
          <>
            <h5 className="text-center">O ESTILO ADAPTA E NEGOCIA (A/N)</h5>
            <p className="paragrafo-inicial">
              As pessoas que possuem este estilo ajudam os outros a manterem-se em contato com as atitudes e o moral dos empregados. Frequentemente elas conseguem facilitar as operações
              através do uso de contatos informais tanto dentro como fora da organização. Chamam a atenção sobre possibilidades que não foram exploradas e advogam um enfoque flexível.
              Elas são muito sensíveis às reações dos clientes. As comunicações com clientes, empregados e público, podem ser auxiliadas pela habilidade que tem em dizer as coisas com tato
              e diplomacia. Nas reuniões, elas são sensíveis à tensão e focalizam sua atenção nas possibilidades de soluções vitoriosas. O seu senso de humor pode ser útil nas negociações
              quando utilizado para conseguir distanciamento e equilíbrio do processo. Elas também são sensíveis à necessidade de realimentação e reconhecimento positivo.
              Quando se serve produtivamente de suas características pessoais, distingue-se pelo tato no relacionamento, pela abertura às trocas com pessoas, dando e recebendo facilmente
              comunicação. A pessoa <strong>Adapta e Negocia</strong> é marcantemente entusiasta e jovial.
            </p>
            <p className="paragrafo-inicial custom-margim-top1">
              <span><strong>Paradoxos da sua Força e Fraqueza</strong></span> <br />
              Sob condições de excesso, ela impede que situações de conflito sejam acentuadas a ponto de não chegar a colher os frutos que tal situação poderia oferecer. Pode dar tanta ênfase
              ao relacionamento que deixa de lado a orientação para a tarefa e a resolução dos problemas. Força tanto o ser agradável que muda de posição muito rapidamente sendo excessivamente
              flexível. <br />
              Quando dirige um grupo, procura impor-se pela aceitação pessoal. É o <strong>Adapta e Negocia</strong> que consegue assumir a posição de estar predominantemente orientado pela clientela. Mostra-se
              produtivo quando informações delicadas devem ser comunicadas com tato. Procura o enfoque da negociação quando o conflito está armado.
            </p>
            <div className="paragrafo-inicial custom-margim-top1">
              <span><strong>O estilo Adapta e Negocia sente-se motivado quando:</strong></span> <br />
              <ul>
                <li>
                  O cargo em que estão oferece-lhes oportunidades de estar entre pessoas, podendo exercer suas habilidades sociais e sendo valorizadas por conseguirem lidar com pessoas consideradas
                  de difícil tratamento. Para elas é muito confortável sentir que todos são amigos e que têm recebido feedback positivo sobre suas atuações, sabendo, portanto, o quanto os outros
                  estão satisfeitos com elas, não precisando enfrentar situações nas quais sintam-se ridículas perante o grupo. Essas pessoas sentir-se-ão melhor quando houver flexibilidade que
                  lhes permita rever pontos de vista e no qual todos estejam dispostos a fazer concessões.
                </li>
                <li className="custom-margim-top2">
                  O ambiente que lhes traz maior conforto é aquele no qual o tipo de supervisão recebida é flexível; não se vendo forçadas a seguirem horários com rigidez, mas podendo dedicar-se ao
                  que fazem quando sentem oportuno. Para tanto, um clima de bom humor, no qual a sociabilidade impere, os demais gostem de assuntos variados, os gostos pessoais sejam sofisticados
                  e sejam valorizadas as origens sociais requintadas, os fazem sentir-se extremamente bem.
                </li>
              </ul>
              A frase: <strong>O que fazer para que os outros comprem esta nova idéia sem se sentirem melindrados</strong>,
              tem forte repercussão para o indivíduo <strong>Adapta e Negocia</strong> no sentido de disparar sua ação eficiente no meio ambiente.
            </div>
            <div className="paragrafo-inicial custom-margim-top1">
              <span><strong>O estilo Adapta e Negocia se sente desmotivado quando:</strong></span> <br />
              <ul>
                <li>
                  O trabalho que executam caracteriza-se especialmente pelo isolamento social, estando rigidamente confinadas a atividade muito delimitadas e não podendo sentir o retorno de suas
                  atividades em termos de suas importâncias pessoais frente aos demais, como se estivessem esquecidas por todos. Atividades muito repetitivas e que não deixam margem ao uso da
                  criatividade, nas quais deve-se atender ao pé da letra roteiros de ação inflexíveis e cheios de detalhes são fonte de desagrado para este estilo.
                </li>
                <li className="custom-margim-top2">
                  O relacionamento pessoal é formal e sério demais, e desencorajador quanto as suas "tiradas" humorísticas. A inflexibilidade de um supervisor desagrada o indivíduo de estilo <strong>Adapta e Negocia</strong>,
                  da mesma forma que chamar sua atenção colocando-o em ridículo perante o grupo faz com ele sinta-se miseravelmente mal; portanto, o tipo de autoridade rígida e crítica
                  tem pouca chance de ser eficiente com ele. Um relacionamento impessoal, que o impeça de sentir-se distinguido perante o grupo, incomoda-o a ponto de chegar a atuar de forma
                  inconveniente para que consiga a atenção que os outros lhe negam.
                </li>
              </ul>
            </div>
          </>
        }
        {this.props.estiloProdutivo.label === 'D/A' &&
          <>
            <h5 className="text-center">O ESTILO DÁ E APÓIA (D/A)</h5>
            <p className="paragrafo-inicial">
              As pessoas que enfatizam este estilo costumam ser especialmente sensíveis à importância do estabelecimento de metas e objetivos de longo prazo. Elas levantarão perguntas a
              respeito da qualidade de objetivos e padrões, lembrarão da necessidade de ser íntegro e ético ao lidar com as pessoas, de se estar bastante preocupado com a política que se
              estabelece no trato com empregados; aprovarão os métodos de participação em grupo e preocupar-se-ão no sentido de que a organização cumpra aquilo que foi prometido.
              Pode-se esperar delas grande envolvimento em assuntos relativos à formação de grupos de trabalho e cooperação, da mesma maneira que em planos de treinamento e desenvolvimento e
              pode-se contar com elas para atender aos apelos de ajuda. Durante o período experimental de planejamento e discussão, essas pessoas podem ser especialmente úteis em dar cobertura
              a uma ampla gama de ideias. <br />
              Usando suas características produtivamente, a pessoa que tem este estilo oferece ajuda e orientação às pessoas que a procuram, ao mesmo tempo em que sustenta fortemente as metas e
              objetivos da organização perante o grupo. Ela será o elemento que dentro do grupo não somente colocará, como empenhar-se-á pelo atingimento de altos padrões de desempenho. <br />
              O uso excessivo das suas características pessoais, em termos de comportamento grupal, levará o indivíduo <strong>Dá e Apóia</strong> a procurar evitar situações ou assuntos que possam acarretar
              sentimentos duros, assumindo para si mesmo o fracasso e o desapontamento quando falha. Pode armazenar sentimentos negativos por muito tempo e de repente, explodir. Assume o
              desapontamento tornando-se crítico com aqueles que não se empenharam em conseguir os altos padrões de desempenho. <br />
              Como alguém que dirige o grupo assume o papel de desenvolver e orientar os subordinados, ao mesmo tempo em que procura amortecer as pressões, problemas e solicitações do
              meio-ambiente. Oferece ajuda não só às pessoas que estão dentro, como também fora da organização.
            </p>
            <p className="paragrafo-inicial custom-margim-top1">
              <span><strong>Paradoxos da sua Força e Fraqueza</strong></span> <br />
              À força de tanto se preocupar com os outros, acaba esquecendo-se de si; enfatiza tanto a ajuda aos outros que pode tornar-se superprotetor e impedir o amadurecimento dos demais.
              Assume tão intensamente as responsabilidades que fica muito comprometido. Sua preocupação com a busca do excelente pode transformá-lo num perfeccionista.
            </p>
            <div className="paragrafo-inicial custom-margim-top1">
              <span><strong>O estilo Dá e Apóia sente-se motivado quando:</strong></span> <br />
              <ul>
                <li>
                  Podem seguir, de preferência, a orientação grupal, isto é, buscam consultar os outros e preferem ser pessoalmente consultados. Seu conforto pessoal é maior quando sentem que seus
                  talentos pessoais são necessários a outros e que estão trabalhando em algo significativo, importante, isto é, quando o resultado de sua atuação contribui diretamente para o maior
                  desenvolvimento da organização ou das pessoas.
                </li>
                <li className="custom-margim-top2">
                  São tratadas com sinceridade, da mesma forma que lidam com os outros, honestamente dizendo a elas aquilo que acreditam lhes ser o melhor; isto tudo dentro de um clima de seriedade
                  e respeito no qual cada um esteja realmente comprometido com seu autodesenvolvimento e em dar o melhor de si para fazer jus às responsabilidades que lhe foram atribuídas.
                </li>
              </ul>
              A frase: <strong>Preciso de sua ajuda</strong>, vai de encontro às expectativas para essas pessoas se porem em ação.
            </div>
            <div className="paragrafo-inicial custom-margim-top1">
              <span><strong>O estilo Dá e Apóia se sente desmotivado quando:</strong></span> <br />
              <ul>
                <li>
                  O tratamento que recebem é frio e impessoal, devendo executar atividades que não têm para elas nenhum significado, não recebendo nem podendo oferecer orientações mais próximas
                  e pessoais. É-lhes extremamente frustrante sentir que a única recompensa que a organização pode lhes oferecer é seu salário no fim do mês e que seus esforços nada significam em
                  termos de contribuição pessoal à organização como um todo.
                </li>
                <li className="custom-margim-top2">
                  Vêem-se obrigadas a freqüentar um ambiente onde a falsidade impera e onde uns tiram partido dos outros, sendo fortalecidos os grupos de poder e as "panelinhas". É-lhes extremamente
                  frustrante não sentir que as pessoas como tal estejam sendo levadas a sério e que se brinca com os problemas humanos.
                </li>
              </ul>
            </div>
          </>
        }
      </div>
    )
  }
}