import React from 'react';
import Cookies from 'js-cookie';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Form,
} from 'reactstrap';
import { Spinner } from 'reactstrap';
import Select from 'react-select';
import Api from '../../config/Api';
import Notification from '../Notification/Notification';
import CheckDuplicity from '../../utils/CheckDuplicity/CheckDuplicity';
import Media from 'react-media';
import { Link } from 'react-router-dom';

export default class Quiz extends React.Component {
  documentData;
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('TOKEN'),
      usuario: Cookies.get('USERNAME'),
      perguntaOpcoes: [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 }],
      usuarioRespostas: [],
      alternativaRespostas: {},
      questionPerPage: 1,
      loading: true,
      page: 1,
      respondido: false,
      hideSubmitButton: false,
    };
    this.api = new Api(this.state.token, (err) => {
      if (err.response.status === 401)
        this.props.history.push({
          pathname: '/login',
          state: { previousURL: this.props.location.pathname }
        });
      return Promise.reject(err);
    });
    this.checkDuplicity = new CheckDuplicity();
  }

  async componentDidMount() {
    const respostas = this.getRespostas();
    const alternativas = this.getAlternativas(this.state.page);
    await Promise.all([respostas, alternativas]);
    this.handleCurrentQuestion(this.state.page);
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 100);
  }

  getAlternativas = (page) => {
    const alternativasStorage = this.documentData = JSON
      .parse(localStorage.getItem(page));

    if (alternativasStorage) {
      this.setState({
        usuarioAlternativas: alternativasStorage,
      });
    } else {
      this.setState({
        usuarioAlternativas: [
          { id: 0, value: 0, label: 0, identificador: 'a', perguntaNumber: '' },
          { id: 0, value: 0, label: 0, identificador: 'b', perguntaNumber: '' },
          { id: 0, value: 0, label: 0, identificador: 'c', perguntaNumber: '' },
          { id: 0, value: 0, label: 0, identificador: 'd', perguntaNumber: '' },
        ],
      })
    }
  }

  getRespostas = async () => {
    try {
      let usuarioRespostas;

      const response = await this.api.userAnswers(this.state.usuario);
      if (response.data.respostas && response.data.respostas.length) {
        usuarioRespostas = response.data.respostas;
        this.setState({
          usuarioRespostas,
          respondido: true,
        });
        return;
      }
      const respostasStorage = this.documentData = JSON
        .parse(localStorage.getItem('respostas'));
      if (respostasStorage) {
        usuarioRespostas = respostasStorage;
        this.setState({
          usuarioRespostas,
        });
      }
    } catch (err) {
      console.log(err)
    }
  }


  setItemLocalStorage = (itemName, item) => {
    localStorage.setItem(`${itemName}`, JSON.stringify(item));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    })

    if (!this.checkDuplicity.handleToUser(this.state.usuarioAlternativas)) {
      return;
    }

    const sendObject = {
      usuario: this.state.usuario,
      answers: this.state.usuarioRespostas,
    };

    try {
      const response = await this.api.answerQuiz(sendObject);
      if (response.data.code === 'ok') {
        this.abriNotificacaoSucesso('Questionário respondido com sucesso!');
        setTimeout(() => {
          this.setState({
            hideSubmitButton: true,
            respondido: true,
            loading: false,
          });
          this.props.history.push({
            pathname: '/resultado',
          });
        }, 500);
        await this.getRespostas();
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleCurrentQuestion = (pageNumber) => {
    const indexOfLastQuestion = !pageNumber ? this.state.page : pageNumber * this.state.questionPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - this.state.questionPerPage;
    const currentQuestion = this.props.quiz.slice(indexOfFirstQuestion, indexOfLastQuestion);
    this.setState({
      currentQuestion,
    });
  }

  handlePaginationButton = (param) => (evt) => {
    if (!this.state.respondido) {
      if (param === 'soma' &&
        this.state.usuarioAlternativas.some((alternativa) => alternativa.id === 0)) {
        this.abrirNotificacaoErro('Todas as alternativas devem serem preenchidas!');
        return;
      }
      if (param === 'soma' && !this.checkDuplicity
        .handleToUser(this.state.usuarioAlternativas)) {
        return;
      }
    }

    let page = this.state.page;
    page = param === 'soma' ? page + 1 : page - 1;

    this.setState({
      page,
    });
    this.handleCurrentQuestion(page);
    this.getAlternativas(page);
  }

  handleOpcaoChange = (id, idx) => (evt) => {
    let [perguntaNumber] = this.state.currentQuestion;
    perguntaNumber = perguntaNumber.number;
    this.state.usuarioAlternativas.forEach((alternativa, sidx) => {
      if (idx !== sidx) return;
      alternativa.id = id;
      alternativa.value = evt.value;
      alternativa.label = evt.value;
      alternativa.perguntaNumber = perguntaNumber;
    });

    if (this.state.usuarioAlternativas.some((alternativa) => alternativa.id === 0)) {
      // check to not setState usuarioAlterantivas before user answer all alternatives
      return;
    } else {
      if (!this.checkDuplicity.handleToUser(this.state.usuarioAlternativas)) {
        // check to duplicity
        return;
      }
      this.setItemLocalStorage(this.state.page, this.state.usuarioAlternativas);
      const respostas = [...this.state.usuarioRespostas];
      const currentAlternativa = this.state.usuarioAlternativas;

      // handle to edit alternativa already answered
      if (this.handleToEditAlternatives(respostas, currentAlternativa)) {
        const filteredRespostas = respostas.filter((resposta) => resposta[0].perguntaNumber
          !== currentAlternativa[0].perguntaNumber);
        filteredRespostas.push(currentAlternativa);
        this.setItemLocalStorage('respostas', filteredRespostas);
        this.setState({
          usuarioRespostas: filteredRespostas,
        });
        return;
      }

      respostas.push(this.state.usuarioAlternativas);
      this.setItemLocalStorage('respostas', respostas);
      this.setState({
        usuarioRespostas: respostas,
      });
    }
  }

  handleToEditAlternatives = (respostasArray, currentAlternativa) => {
    return respostasArray.find((resposta) => resposta[0].perguntaNumber
      === currentAlternativa[0].perguntaNumber);
  }

  handleAlternativaDefaultValue = (id) => {
    if (this.state.respondido) {
      return this.state.usuarioRespostas.find((alternativa) => alternativa.pergunta_opcao_id === id);
    }
    if (this.state.usuarioAlternativas) {
      return this.state.usuarioAlternativas.find((alternativa) => alternativa.id === id);
    }
  }

  abrirNotificacaoErro = (message) => Notification
    .createNotification('error', message)

  abriNotificacaoSucesso = (message) => Notification
    .createNotification('success', message ? message : 'Operação realizada com sucesso!')

  render() {
    return (
      <>
        {this.state.loading ?
          <div style={{ textAlign: 'center' }}>
            <Spinner style={{
              width: '7rem',
              height: '7rem',
              color: '#2B6936',
            }} />
          </div> :
          <Form onSubmit={this.handleSubmit}>
            {this.state.currentQuestion && this.state.currentQuestion.map((question) => {
              return (
                <div key={question.id}>
                  <h4>{`${question.number}/ 18 - ${question.descricao}`}</h4>
                  {question.opcoes.map((opcao, idx) => {
                    return (
                      <Row key={opcao.id}>
                        <Col md="6">
                          <FormGroup>
                            <Label for="opcao">{opcao.texto}</Label>
                            <Select
                              options={this.state.perguntaOpcoes}
                              onChange={this.handleOpcaoChange(opcao.id, idx)}
                              placeholder="Selecione"
                              defaultValue={this.handleAlternativaDefaultValue(opcao.id)}
                              isDisabled={this.state.respondido}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )
                  })}
                </div>
              )
            })}
            <div>
              {this.state.page > 1 &&
                <button
                  type="button"
                  className="btn btn-success question-button-anterior"
                  onClick={this.handlePaginationButton('subtrai')}
                >
                  <i className="fas fa-arrow-left" />
                </button>
              }
              {this.state.page <= 17 &&
                <button
                  type="button"
                  className="btn btn-success question-button-proxima"
                  onClick={this.handlePaginationButton('soma')}
                >
                  <i className="fas fa-arrow-right" />
                </button>
              }
              {this.state.page === 18 && this.state.usuarioRespostas.length >= 18 &&
                < button
                  type="button"
                  className="btn btn-success question-button-proxima"
                  onClick={this.handleSubmit}
                  disabled={this.state.hideSubmitButton || this.state.respondido}
                >
                  Enviar
                </button>
              }
              {this.state.respondido &&
                <Row>
                  <Col>
                    <Media query="(max-width: 500px)" render={() => (
                      <>
                        <br />
                        <p className="paragrafo-descricao">
                          <br />
                          <Link
                            className="btn btn-success"
                            to="/resultado"
                          >
                            Resultado
                        </Link>
                        </p>
                      </>
                    )} />
                  </Col>
                </Row>
              }
            </div>
          </Form>
        }
      </>
    )
  }
}