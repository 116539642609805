import React from 'react';
import {
  FormGroup,
  Label,
  // Input,
  Form,
  Col,
  Row,
} from 'reactstrap';
import Select from 'react-select';

export default class ExemploModal extends React.Component {
  handlePerguntaOptions = () => {
    return [
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 }];
  }

  render() {
    return (
      <div className="content">
        <Form className="form-exemplo">
          <h4>Na maioria das vezes eu sou: </h4>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="pergunta1">a) de bom gênio e alegre.</Label>
                <Select
                  options={this.handlePerguntaOptions()}
                  onChange={this.handlePerguntaChange}
                  placeholder="Selecione"
                  className="table-responsive"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="pergunta2">b) disposto para o trabalho e cheio de planos.</Label>
                <Select
                  options={this.handlePerguntaOptions()}
                  onChange={this.handlePerguntaChange}
                  placeholder="Selecione"
                  className="table-responsive"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="pergunta2">c) econômico e atento.</Label>
                <Select
                  options={this.handlePerguntaOptions()}
                  onChange={this.handlePerguntaChange}
                  placeholder="Selecione"
                  className="table-responsive"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="pergunta2">d) charmoso e popular.</Label>
                <Select
                  options={this.handlePerguntaOptions()}
                  onChange={this.handlePerguntaChange}
                  placeholder="Selecione"
                  className="table-responsive"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}