import React from 'react';

export default class EstiloModalQueda extends React.Component {
  render() {
    return (
      <div className="content">
        {this.props.estiloQueda.label === 'M/C' &&
          <>
            <h5 className="text-center">O ESTILO MANTÉM E CONSERVA (M/C)</h5>
            <p className="paragrafo-inicial">
              Você tende a se prender muito a fatos e inundar os outros com dados para vencer uma
              discussão, depois se retira, matem sua posição e espera para ver uma reação, ou até que a competição
              chegue a você. Você tende a superanalisar um problema e, portando, a colecionar dados desnecessários. Você
              se atém, possessivamente, aquilo que tem, protege-se e fica atento a seus próprios interesses, mesmo que
              possa fazer os outros pensarem que você é teimoso, frio ou indiferente. Você tende a se apegar demais ao
              velho em vez de aceitar o novo.
            </p>
          </>
        }
        {this.props.estiloQueda.label === 'T/C' &&
          <>
            <h5 className="text-center">O ESTILO TOMA E CONTROLA (T/C)</h5>
            <p className="paragrafo-inicial">
              Você tende a usar o estilo ”ou vai ou racha”. Você se torna vigoroso demais lutando por
              seus direitos e usando o rolo compressor, decidido a não deixar ninguém passá-lo para trás. Você pode tentar
              medir forças intelectuais com seu adversário e entrar numa polêmica radical (ganha-perde), mesmo que seja
              para vencer a batalha e perder a guerra. Os outros podem achar você super agressivo.
            </p>
          </>
        }
        {this.props.estiloQueda.label === 'A/N' &&
          <>
            <h5 className="text-center">O ESTILO ADAPTA E NEGOCIA (A/N)</h5>
            <p className="paragrafo-inicial">
              Você tende a transigir demais, adaptando-se e seguindo muito facilmente as opiniões dos
              outros. Você tenta evitar o conflito, manter a paz e a harmonia a todo custo. Você, frequentemente, tende a
              apaziguar os criadores de casos procurando gracejar sobre o problema, levando na brincadeira, e negando a
              preocupação real. Você tende a suavizar as coisas com seu charme, a esconder seus sentimentos reais e a
              conciliar para manter todos contentes, mesmo que você parece ansioso demais para obter aprovação dos outros.
            </p>
          </>
        }
        {this.props.estiloQueda.label === 'D/A' &&
          <>
            <h5 className="text-center">O ESTILO DÁ E APÓIA (D/A)</h5>
            <p className="paragrafo-inicial">
              Você tende a ser excessivamente útil, acreditando nos outros e confiando e concedendo demais.
              Você aceita o ponto de vista dos outros muito facilmente afim de ser considerado um bom sujeito. Você tende a
              perguntar demais a opinião dos outros, não colocando as suas com firmeza, e perguntando o que fazer. Você
              tende a assumir rapidamente a culpa pelo conflito, acreditando que você seja a causa dele e procurando uma
              maneira de conciliar. Você pode parecer um fraco aos olhos dos outros.
            </p>
          </>
        }
      </div>
    )
  }
}