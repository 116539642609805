import React from "react";
import Cookies from 'js-cookie';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import Quiz from '../../components/Quiz/Quiz';
import { Spinner } from 'reactstrap';
import Api from '../../config/Api';

export default class Lifo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('TOKEN'),
      formId: 1,
      quiz: [],
      loading: true,
    };

    this.api = new Api(this.state.token, (err) => {
      if (err.response.status === 401)
        this.props.history.push({
          pathname: '/login',
          state: { previousURL: this.props.location.pathname }
        });
      return Promise.reject(err);
    });
  }

  async componentDidMount() {
    await this.getQuiz();
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 400);
  }

  getQuiz = async () => {
    try {
      const response = (await this.api.getQuiz(this.state.formId));
      const sortedQuiz = response.data.sort((a, b) => a.number - b.number);
      this.setState({
        quiz: sortedQuiz,
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="11">
              <Card className="card-upgrade">
                <CardHeader className="text-center">
                  <CardTitle tag="h4">Lifo <i className="far fa-registered registered-icon-form" /> - Life Orientation
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td>
                          {this.state.loading ?
                            <div style={{ textAlign: 'center' }}>
                              <Spinner style={{
                                width: '7rem',
                                height: '7rem',
                                color: '#2B6936',
                              }} />
                            </div> :
                            <Quiz
                              quiz={this.state.quiz}
                              history={this.props.history}
                            />
                          }
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
