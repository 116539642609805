import Inicio from '../views/Inicio/PaginaInicial';
import Resultado from "views/Resultado/Resultado";
import Form from '../views/Form/Lifo';

const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-home",
    component: Inicio,
  },
  {
    path: "/questionario",
    name: "Questionário",
    icon: "nc-icon nc-spaceship",
    component: Form,
  },
  {
    path: "/resultado",
    name: "Resultado",
    icon: "fas fa-poll",
    component: Resultado,
  },
  {
    redirect: true, path: '/', pathTo: '/home', name: 'Home',
  },
];
export default routes;
