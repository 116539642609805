import React from 'react';
import { Collapse } from 'reactstrap';

export default class Estilos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse1: true,
      collapse2: false,
      collapse3: false,
      collapse4: false
    }
  }
  toggleCollapse = (evt) => {
    this.setState(prevState => ({
      collapse1: !prevState.collapse1,
      collapse2: false,
      collapse3: false,
      collapse4: false,
    }));
  }

  toggleCollapse2 = (evt) => {
    this.setState(prevState => ({
      collapse2: !prevState.collapse2,
      collapse1: false,
      collapse3: false,
      collapse4: false,
    }));
  }

  toggleCollapse3 = (evt) => {
    this.setState(prevState => ({
      collapse3: !prevState.collapse3,
      collapse1: false,
      collapse2: false,
      collapse4: false,
    }));
  }

  toggleCollapse4 = (evt) => {
    this.setState(prevState => ({
      collapse4: !prevState.collapse4,
      collapse1: false,
      collapse2: false,
      collapse3: false,
    }));
  }

  render() {
    return (
      <>
        <button
          type="button"
          className="btn btn-success margim-button-result
                   custom-margim-right-result-button"
          onClick={this.toggleCollapse}
        >
          1º Estilo
        </button>
        <button
          type="button"
          className="btn btn-success
                   custom-margim-right-result-button"
          onClick={this.toggleCollapse2}
        >
          2º Estilo
        </button>
        <button
          type="button"
          className="btn btn-success custom-margim-button3-result
                   custom-margim-right-result-button"
          onClick={this.toggleCollapse3}
        >
          3º Estilo
        </button>
        <button
          type="button"
          className="btn btn-success custom-margim-button4-result
                   custom-margim-right-result-button"
          onClick={this.toggleCollapse4}
        >
          4º Estilo
        </button>
        < Collapse isOpen={this.state.collapse1} >
            {this.props.children1}
        </Collapse >
        < Collapse isOpen={this.state.collapse2} >
            {this.props.children2}
        </Collapse >
        <Collapse isOpen={this.state.collapse3}>
            {this.props.children3}
        </Collapse>
        <Collapse isOpen={this.state.collapse4}>
            {this.props.children4}
        </Collapse>
      </>
    )
  }
}